import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import Tenant from '../app/auth/models/Tenant';
import { useAuthStore } from '../stores/authStore';
import { useEvent } from './useEventBus';

export const useTenantUserSpots = () => {
    // TODO VERIFY REACTIVITY
    const { tenant } = storeToRefs(useAuthStore());

    const spotUnitPrice = ref(900);
    const usedSpots = ref(tenant.value!.users?.count() ?? 1);
    const paidSpots = ref(tenant.value?.paidUsersSpots);

    const sumSpots = ref(tenant.value!.paidUsersSpots + tenant.value!.freeUsersSpots);
    const availableSpots = () => {
        const remaining = sumSpots.value - usedSpots.value;
        if (remaining <= 0) return 0;
        return remaining;
    };
    const monthlyPrice = computed(() => tenant.value!.paidUsersSpots * spotUnitPrice.value);

    const openSpotsModal = () => {
        useEvent('tenancy:tenants:spots-modal:open', {
            tenant: tenant.value as Tenant,
        });
    };
    return {
        spotUnitPrice,
        usedSpots,
        paidSpots,
        availableSpots,
        monthlyPrice,
        openSpotsModal,
    };
};
